import { useRouter } from 'next/router';
import { invalidateQuery, useMutation, useQuery } from '@blitzjs/rpc';
import { useSession } from '@blitzjs/auth';
import React, { SyntheticEvent, useCallback, useContext, useEffect } from 'react';
import { CurrentUserContext, setUser } from './CurrentUserContext';
import { BookmarkIcon, BookmarkIconForCard } from './icons/BookmarkIcons';
import getUserBookmarks from 'app/users/queries/getUserBookmarks';
import { event } from 'app/analytics';
import updateUserBookmark from 'app/users/mutations/updateUserBookmark';
import { UserOnBookmarkIdOnly } from 'app/types';

interface BookmarkButtonProps {
    content: { id: number };
    isIcon?: boolean;
}

const BookmarkButton = ({ content, isIcon = false }: BookmarkButtonProps) => {
    const router = useRouter();
    const user = useContext(CurrentUserContext);
    const session = useSession();
    const [bookmarks] = useQuery(
        getUserBookmarks,
        { idsOnly: true },
        { refetchOnWindowFocus: true, enabled: !!session.userId || !!user },
    );

    const [updateBookmark] = useMutation(updateUserBookmark, {
        onSuccess: (data: UserOnBookmarkIdOnly[]) => {
            invalidateQuery(getUserBookmarks);
            setUser({ ...user, bookmarks: data }, { refetch: false });
        },
    });

    const isBookmark = bookmarks?.some((bookmark) => bookmark.contentId === content.id);

    const onClick = useCallback(
        async (e?: SyntheticEvent) => {
            e?.preventDefault();
            if (!user) {
                event({
                    action: 'click-bookmark-anon',
                });
                router.push(
                    {
                        pathname: '/login',
                        query: { 'return-to': router.asPath + `?subscribe=${content.id}` },
                    },
                    undefined,
                    { shallow: true },
                );
            } else {
                if (user.id && content.id) {
                    event({
                        action: 'click-bookmark',
                    });
                    updateBookmark({ bookmarkContentId: content.id });
                }
            }
        },
        [content, router, user, updateBookmark],
    );

    useEffect(() => {
        if (!user) return;

        const bookmarkIdQuery = router.query?.subscribe;

        if (bookmarkIdQuery && +bookmarkIdQuery === content.id && !isBookmark) {
            router.replace(window.location.pathname, undefined, { shallow: true });
            onClick();
        }
    }, [content.id, onClick, router, user, isBookmark]);

    const titleText = isBookmark ? 'Remove from bookmarks' : 'Add to bookmarks';

    return isIcon ? (
        <button className="tag-box__btn" onClick={onClick} title={titleText}>
            <div className={`favorite-btn ${isBookmark ? '_active' : ''}`}>
                <BookmarkIconForCard />
            </div>
        </button>
    ) : (
        <a href="" className="btn btn--default" onClick={onClick} title={titleText}>
            <BookmarkIcon isBookmark={isBookmark} />
            <div style={isBookmark ? { marginLeft: '0.6rem' } : {}}>
                {isBookmark ? 'Remove from bookmarks' : 'Bookmark'}
            </div>
        </a>
    );
};

export default BookmarkButton;
